export type TypeSubscribeEmail = {
    titleColor: string;
    bgColor: string;
    title: string;
    emarsysType: string;
    isVideo: boolean;
    img: any;
    termsUrl: string;
    policyUrl: string;
};

export const DEFAULT_DATA: TypeSubscribeEmail = {
    titleColor: '#000000',
    bgColor: '#F3F4F7',
    title: '',
    emarsysType: 'WEB_FOOTER',
    isVideo: false,
    img: '',
    termsUrl: 'https://us.ecoflow.com/pages/terms-of-use',
    policyUrl: 'https://account.ecoflow.com/agreement/en-us/PrivacyPolicy.html',
};

export const getLocalText: any = (locale: string, termsUrl: string, policyUrl: string) => {
    const MAP: any = {
        us: {
            subscribe: 'Subscribe',
            placeholder: 'Email address',
            success: 'Submitted successfully',
            error: 'Please enter a valid message',
            check: 'Please tick the checkbox below',
            termsAndPolicy: `By submitting this form, I accept the <a href=${termsUrl}>Terms of Use</a> and <a href=${policyUrl}>Privacy Policy</a>.`,
        },
        cn: {
            subscribe: '订阅',
            placeholder: '邮箱地址',
            success: '提交成功',
            error: '请输入有效的邮箱地址',
            check: '请勾选下方复选框',
            termsAndPolicy: `通过提交此表单，我接受<a href=${termsUrl}>使用条款</a>和<a href=${policyUrl}>隐私政策</a>。`,
        },
        de: {
            subscribe: 'Abonnieren',
            placeholder: 'E-Mail-Adresse',
            success: 'erfolgreich eingereicht',
            error: 'Bitte geben Sie eine gültige Nachricht ein',
            check: 'Bitte aktivieren Sie das Kontrollkästchen unten',
            termsAndPolicy: `Mit dem Absenden dieses Formulars akzeptiere ich die <a href=${termsUrl}>Nutzungsbedingungen</a> und <a href=${policyUrl}>Datenschutzbestimmungen</a>.`,
        },
        fr: {
            subscribe: "S'abonner",
            placeholder: 'Adresse e-mail',
            success: 'Soumis avec succès',
            error: 'Veuillez entrer un message valide',
            check: 'Veuillez cocher la case ci-dessous',
            termsAndPolicy: `En soumettant ce formulaire, j'accepte <a href=${termsUrl}>les conditions d'utilisation</a> et <a href=${policyUrl}>la politique de confidentialité</a>.`,
        },
        it: {
            subscribe: 'Iscriviti',
            placeholder: 'Indirizzo e-mail',
            success: 'Inserito con successo',
            error: 'Inserisci un messaggio valido',
            check: 'Si prega di spuntare la casella di controllo qui sotto',
            termsAndPolicy: `Inviando questo modulo, accetto <a href=${termsUrl}>i Termini di utilizzo</a> e <a href=${policyUrl}>l'Informativa sulla privacy</a>.`,
        },
        es: {
            subscribe: 'Suscribirse',
            placeholder: 'Dirección de correo electrónico',
            success: 'Enviado satisfactoriamente',
            error: 'Por favor introduce un mensaje válido',
            check: 'Por favor marque la casilla de verificación a continuación',
            termsAndPolicy: `Al enviar este formulario, acepto <a href=${termsUrl}>los Términos de uso</a> y <a href=${policyUrl}>la Política de privacidad</a>.`,
        },
        jp: {
            subscribe: '登録する',
            placeholder: 'メールアドレス',
            success: '送信成功しました',
            error: '有効なメールアドレスを入力してください',
            check: '下のチェックボックスをチェックしてください',
            termsAndPolicy: `<a href=${termsUrl}>利用規約</a>と<a href=${policyUrl}>プライバシーポリシー</a>に同意します。`,
        },
        kr: {
            subscribe: '구독',
            placeholder: '메일주소',
            success: '성공적으로 제출되었습니다',
            error: '유효한 이메일 주소를 입력하세요',
            check: '아래 체크박스를 선택해주세요.',
            termsAndPolicy: `EcoFlow의 <a href=${termsUrl}>개인정보 보호정책</a> 및 <a href=${policyUrl}>이용약관</a>에 동의합니다.`,
        },
        tw: {
            subscribe: '訂閱',
            placeholder: '郵件地址',
            success: '提交成功',
            error: '請輸入有效的郵箱地址',
            check: '請勾選下方複選框',
            termsAndPolicy: `提交此表格，我接受<a href=${termsUrl}>使用條款</a>和<a href=${policyUrl}隱私權政策</a>。`,
        },
        sa: {
            subscribe: 'الاشتراك',
            placeholder: 'عنوان البريد الإلكتروني',
            success: 'تم الإرسال بنجاح',
            error: 'يرجى إدخال عنوان بريد إلكتروني صالح',
            check: 'يرجى وضع علامة في خانة الاختيار أدناه',
            termsAndPolicy: 'بإرسال هذا النموذج، أوافق على شروط الاستخدام وسياسة الخصوصية.',
        },
        cis: {
            subscribe: 'подписка',
            placeholder: 'Адрес электронной почты',
            success: 'Отправлено успешно',
            error: 'Пожалуйста, введите действительный адрес электронной почты',
            check: 'Пожалуйста, отметьте флажок ниже',
            termsAndPolicy: `Отправляя эту форму, я принимаю <a href=${termsUrl}>Условия использования</a> и <a href=${policyUrl}Политику конфиденциальности</a>.`,
        },
        br: {
            subscribe: 'Inscrever-se',
            placeholder: 'Endereço de email',
            success: 'Enviado com sucesso',
            error: 'Por favor, insira uma mensagem válida',
            check: 'Por favor, marque a caixa de seleção abaixo',
            termsAndPolicy: `Ao enviar este formulário, aceito os <a href=${termsUrl} target="_blank">Termos de Uso</a> e a <a href=${policyUrl} target="_blank">Política de Privacidade</a>.`,
        },
    };

    return MAP[locale] || MAP.us;
};

export const config = {
    defaultData: DEFAULT_DATA,
    defaultAttr: {},
};
